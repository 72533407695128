import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Checkout from '../pages/Checkout'
import CheckoutSuccess from "../pages/CheckoutSuccess";
import Cart from '../pages/Cart'
import PaymentFeedback from '../pages/PaymentFeedback'
import CookiePolicy from '../pages/CookiePolicy'

Vue.use(VueMeta);
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', component: Cart },
        { path: '/checkout', component: Checkout, name: 'Checkout' },
        { path: '/checkout/success', component: CheckoutSuccess },
        { path: '/payment/feedback/:eshopId/', component: PaymentFeedback},
        { path: '/privacy', component: CookiePolicy, name: "CookiePolicy"}
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
