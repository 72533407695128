<template>
  <v-list-item :key="product.id" class="product-detail py-2 px-0">
    <v-list-item-content>
      <v-list-item-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="text-h6">{{
              product.name
            }}</span>
          </template>
          <span>{{ product.shortDescription }}</span>
        </v-tooltip>
      </v-list-item-title>
      <v-list-item-subtitle
        class="product-description pr-3"
        v-html="product.description"
      />
    </v-list-item-content>
    <v-list-item-action>
      <v-row
        align="center"
        align-content="center"
        class="align-center justify-space-between justify-sm-center"
      >
        <v-col
          cols="12"
          class="
            align-center
            justify-end justify-sm-center
            text-right text-sm-center
            d-flex
          "
        >
          <div class="d-inline-flex pr-4" v-if="$vuetify.breakpoint.smAndUp">
            <strong class="mr-2">{{
              $t("homepage.currency", {
                price: product.amountWithVat,
              })
            }}</strong>
          </div>
          <div class="d-inline-flex">
            <v-btn
              depressed
              min-width="36"
              width="36"
              tile
              color="primary"
              @click="decreaseQty()"
            >
              <v-icon x-small>mdi-minus</v-icon>
            </v-btn>
          </div>
          <div
            class="mx-4 d-inline-flex flex-column align-center justify-center"
          >
            <span>
              {{ getQty() }}
            </span>
          </div>
          <div class="d-inline-flex">
            <v-btn
              depressed
              min-width="36"
              width="36"
              tile
              color="primary"
              @click="increaseQty()"
            >
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.xsOnly">
        <v-col cols="12" class="pt-0">
          <div>
            <strong class="mr-2">{{
              $t("homepage.currency", {
                price: formatThousands(product.amountWithVat),
              })
            }}</strong>
          </div>
        </v-col>
      </v-row>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatThousands } from "../../Helper";

export default {
  name: "ProductDetail",
  props: ["product"],
  mixins: [formatThousands],
  computed: {
    ...mapGetters(["cartProducts"]),
  },
  data() {
    return {
      qty: 0,
    };
  },
  methods: {
    ...mapActions(["addToCart", "removeFromCart", "changeQuantity"]),
    getQty() {
      const cartItem = this.cartProducts.find(
        (p) => p.productId === this.product.id
      );
      if (cartItem) {
        this.qty = cartItem.qty;
        return this.qty;
      }
      this.qty = 0;
      return this.qty;
    },
    decreaseQty() {
      if (this.qty === 0) {
        this.removeFromCart(this.product);
        return (this.qty = 0);
      }

      this.qty = this.qty - 1;
      this.changeQuantity({ product: this.product, qty: this.qty });
    },
    increaseQty() {
      this.qty = this.qty + 1;
      this.addToCart({ product: this.product, qty: this.qty });
    },
  },
};
</script>

<style lang="scss">
.product-detail {
  .v-toolbar__content {
    padding-left: 0;
  }
  .product-description {
    overflow: inherit !important;
    text-overflow: initial !important;
    white-space: initial !important;
    p {
      margin-bottom: 0;
    }
  }
  .price {
    font-size: 22px;
  }
  .vue-numeric-input {
    height: 28px;
    input {
      outline: none;
      height: 100%;
    }
    .btn {
      background: transparent;
      box-shadow: none;
      border: none;
      &:hover {
        background: #fa3600;
      }
    }
  }
}
</style>
