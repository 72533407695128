<template>
  <v-container fluid grid-list-lg class="cart pa-0">
    <v-container fluid class="grey lighten-3 white--text">
      <v-row>
        <v-col cols="12" class="text-center">
          <h2
            class="font-weight-light text-uppercase py-2 mb-0 black--text"
            :class="{ 'text-h5': $vuetify.breakpoint.smAndUp }"
          >
            {{ $t("category.openingHours") }}:
            <strong>{{ openingHours }}</strong>
          </h2>
          <p class="black--text">{{ $t("category.validTickets") }}</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mb-10">
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <v-card tile>
            <v-card-text>
              <v-list two-line class="pt-0">
                <template v-for="(product, index) in allProducts">
                  <product-detail :product="product" :key="product.id" />
                  <v-divider
                    :key="product.id + '_divider'"
                    v-if="index + 1 !== allProducts.length"
                  />
                </template>
              </v-list>
              <div class="text-center">
                <v-tooltip bottom :disabled="itemsInCart > 0">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        large
                        :block="$vuetify.breakpoint.xsOnly"
                        tile
                        color="primary"
                        depressed
                        :disabled="!itemsInCart"
                        to="/checkout"
                      >
                        {{ $t("cart.checkout") }}
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ $t("cart.addToCartFirst") }}</span>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProductImage, formatThousands } from "../Helper";
import ProductDetail from "../components/Product/ProductDetail";

export default {
  name: "Cart",
  components: { ProductDetail },
  mixins: [getProductImage, formatThousands],
  computed: {
    ...mapGetters(["allProducts", "cartProducts", "user"]),
    openingHours() {
      const date = new Date();
      const currentMonth = date.getMonth();
      switch (currentMonth) {
        case 0:
        case 1:
        case 10:
        case 11:
          return "8:00 - 17:00";
        case 2:
          return "7:00 - 18:00";
        case 3:
        case 9:
          return "7:00 - 19:00";
        default:
          return "7:00 - 20:00";
      }
    },

    itemsInCart() {
      let cart = this.cartProducts;
      if (!cart[0]) return null;
      return cart.reduce((accum, item) => accum + item.qty, 0);
    },
    total() {
      if (this.cartProducts[0] === null) return null;
      return this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);
    },
    totalDiscount() {
      if (this.cartProducts[0] === null) return null;
      let total = this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);

      if (this.discount) {
        return parseInt(total) - this.discount;
      }

      return total;
    },
    discount() {
      if (this.user && this.user.discount) {
        return Math.round(this.total * this.user.discount);
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["changeQuantity", "removeFromCart"]),
    getPrice(price, quantity) {
      return quantity * price;
    },
    changeValue(product) {
      this.changeQuantity(product);
    },
  },
};
</script>

<style lang="scss">
.cart {
  .mobile-table {
    font-size: 14px;
  }
  .item-price {
    font-size: 16px;
  }
  .order-total {
    font-size: 18px;
    .amount {
      font-size: 22px;
    }
  }
  .vue-numeric-input {
    input {
      outline: none;
      height: 100%;
    }
    .btn {
      background: transparent;
      box-shadow: none;
      border: none;
      &:hover {
        background: #fa3600;
      }
    }
  }
  .empty-cart {
    font-size: 86px !important;
    color: rgba(135, 135, 135, 0.15) !important;
  }
}
</style>

<style lang="scss">
.v-list-item__title {
  white-space: unset;
}
</style>
