<template>
  <v-container class="black--text copyright pa-0 text-center text-sm-left">
    <v-row
      class="align-center"
      :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
    >
      <v-col>
        <p class="mb-2">{{ $t("footer.name") }}</p>
        <div
          class="d-flex"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <p class="mb-0 mr-2">Zámek 1, 252 43 Průhonice</p>
          <v-divider class="grey" vertical />
          <p class="mb-0 mx-2">park@ibot.cas.cz</p>
          <v-divider class="grey" vertical />
          <p class="mb-0 ml-2">+420 271 015 180</p>
        </div>
      </v-col>
      <v-col class="text-center text-sm-right">
        <a
          target="_blank"
          class="black--text mr-2"
          href="http://www.pruhonickypark.cz/cs/"
          >{{ $t("footer.title") }}</a
        >
        <a
          target="_blank"
          class="black--text"
          :href="$t('footer.privacyPolicyPdf')"
          >{{ $t("footer.privacyPolicy") }}</a
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<style lang="scss">
.copyright {
  font-size: 14px;
}
.no-decoration {
  text-decoration: none;
}
</style>
