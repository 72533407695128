<template>
  <v-form
    v-model="valid"
    ref="checkoutForm"
    lazy-validation
    @submit.prevent="handleFormSubmit"
    @keyup.native.enter="submit($event)"
  >
    <v-container class="checkout">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <delivery-address
            :refs="refs"
            :form="form"
            @validateEmail="validatingEmail"
          />
          <delivery-methods :form="form" />
          <payment-methods :form="form" />
        </v-flex>
        <v-flex xs12 sm6>
          <summary-block :form="form" />
          <place-order :form="form" :loading="loading" @submit="submit" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeliveryAddress from "../components/Checkout/DeliveryAddress";
import Summary from "../components/Checkout/Summary";
import DeliveryMethods from "../components/Checkout/DeliveryMethods";
import PaymentMethods from "../components/Checkout/PaymentMethods";
import PlaceOrder from "../components/Checkout/PlaceOrder";

export default {
  name: "Checkout",
  metaInfo() {
    return {
      title: "Pokladna",
    };
  },
  components: {
    DeliveryAddress,
    "summary-block": Summary,
    DeliveryMethods,
    PaymentMethods,
    PlaceOrder,
  },
  data() {
    return {
      form: {},
      valid: true,
      loading: false,
      refs: undefined,
    };
  },
  computed: {
    ...mapGetters([
      "cartProducts",
      "user",
      "paymentMethods",
      "paymentMethodsLoaded",
      "shippingMethods",
      "shippingMethodsLoaded",
    ]),
    checkoutCartItems() {
      let items = [];
      if (this.cartProducts[0] === null) return null;
      for (let i in this.cartProducts) {
        items.push({
          productId: this.cartProducts[i].productId,
          qty: this.cartProducts[i].qty,
        });
      }
      return items;
    },
  },
  watch: {
    shippingMethodsLoaded: function (val) {
      if (val && this.shippingMethods.length) {
        this.form.shippingMethodId = this.shippingMethods[0].id;
        if (this.$gtm.enabled()) {
          this.$gtm.trackEvent({
            event: "checkoutOption", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              checkout_option: {
                actionField: {
                  step: 1,
                },
                option: this.shippingMethods[0].name,
              },
            },
          });
        }
      }
    },
    paymentMethodsLoaded: function (val) {
      if (val && this.paymentMethods.length) {
        this.form.paymentMethodId = this.paymentMethods[0].id;
        if (this.$gtm.enabled()) {
          this.$gtm.trackEvent({
            event: "checkoutOption", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              checkout_option: {
                actionField: {
                  step: 2,
                },
                option: this.paymentMethods[0].name,
              },
            },
          });
        }
      }
    },
    checkoutCartItems: function (newValue) {
      if (newValue) {
        let products = [];

        for (let i in this.cartProducts) {
          products.push({
            id: this.cartProducts[i].productId,
            name: this.cartProducts[i].name,
            category: this.cartProducts[i].productCategoryName,
            price: this.cartProducts[i].amountWithVat,
            quantity: this.cartProducts[i].qty,
          });
        }
        if (this.$gtm.enabled()) {
          this.$gtm.trackEvent({
            event: "checkout", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              checkout: {
                actionField: {
                  step: 1,
                },
                products: products,
              },
            },
          });
        }
      }
    },
  },
  beforeMount() {
    this.form.customer = {};
    this.form.customer.address = {};
    if (this.user) {
      this.form.customer.firstName = this.user.firstName;
      this.form.customer.lastName = this.user.lastName;
      this.form.customer.email = this.user.email;
      this.form.customer.phone = this.user.phone;
    }
    if (this.shippingMethodsLoaded && this.shippingMethods.length) {
      this.form.shippingMethodId = this.shippingMethods[0].id;
    }

    if (this.paymentMethodsLoaded && this.paymentMethods.length) {
      this.form.paymentMethodId = this.paymentMethods[0].id;
    }
  },
  mounted() {
    if (!this.cartProducts.length) {
      this.$router.push("/");
    }
    this.refs = this.$refs;
  },
  methods: {
    ...mapActions(["handleFormSubmit", "setOrderId", "emptyCart"]),
    submit(event) {
      event.preventDefault();
      this.form.langCode = this.$i18n.locale;
      if (this.$refs.checkoutForm.validate()) {
        this.loading = true;
        this.form.eshopId = process.env.VUE_APP_API_ESHOP_ID;
        this.form.items = this.checkoutCartItems;
        window.axios
          .post(
            process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_CART,
            this.form
          )
          .then((response) => {
            this.onSuccess(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onSuccess(response) {
      this.setOrderId(response.data);
      this.emptyCart();
      this.loading = false;
      if (response.data.paymentUrl) {
        window.location.replace(decodeURI(response.data.paymentUrl));
      } else {
        this.$router.push("/checkout/success");
      }
    },
    validatingEmail() {
      this.loading = !this.loading;
    },
  },
};
</script>

<style lang="scss">
.checkout {
  .checkout-section-title {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;
  }
}
</style>
